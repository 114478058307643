<template>
    <div class="container">
        <!-- 上传身份证 -->
        <div class="identity_card">
            <div class="title">
                <div class="symbol">*</div>
                <div class="text">上传身份证照片</div>
            </div>
            <div class="uploadBox">
                <van-uploader class="van-uploader" :after-read="afterRead1">
                    <div v-if="imageSrc1 === ''" class="uploadCard">
                        <img src="https://lingdanyun.oss-cn-beijing.aliyuncs.com/xcx/IDCardT.jpg" alt="">
                    </div>
                    <div v-else class="uploadCard">
                        <img :src="imageSrc1">
                    </div>
                </van-uploader>
                <van-uploader class="van-uploader" :after-read="afterRead2">
                    <div v-if="imageSrc2 === ''" class="uploadCard">
                        <img src="https://lingdanyun.oss-cn-beijing.aliyuncs.com/xcx/IDCardF.jpg" alt="">
                    </div>
                    <div v-else class="uploadCard">
                        <img :src="imageSrc2">
                    </div>
                </van-uploader>
            </div>
        </div>
        <!-- 上传个人信息 -->
        <div class="personalInfo">
            <div class="infoCell">
                <div class="title">
                    <div class="symbol">*</div>
                    <div class="text">姓名</div>
                </div>
                <div class="value">{{ userInfo.name }}</div>
            </div>
            <div class="infoCell">
                <div class="title">
                    <div class="symbol">*</div>
                    <div class="text">手机号</div>
                </div>
                <div class="value">{{ userInfo.mobile }}</div>
            </div>
            <div class="infoCell">
                <div class="title">
                    <div class="symbol">*</div>
                    <div class="text">身份证号</div>
                </div>
                <div class="value">{{ userInfo.personal_social_no }}</div>
            </div>
            <div class="infoBankCell">
                <div class="title">
                    <div class="symbol">*</div>
                    <div class="text">银行卡号</div>
                </div>
                <div class="bankInput"><van-field maxlength="19" type="number" v-model="bankNumber"
                        placeholder="请输入银行卡号" /></div>
            </div>
        </div>
        <!-- 保存按钮 -->
        <div class="save" @click="save">保存</div>
    </div>
</template>
<script setup>
import imageCompression from 'browser-image-compression';
import { ref, onMounted } from 'vue'
import { get, post } from '@/utils/http';
import { useRouter } from 'vue-router'
import { showToast } from 'vant';
const bankNumber = ref()
const router = useRouter()
const imageSrc1 = ref(''); // 初始化为空字符串  
const imageSrc2 = ref(''); // 初始化为空字符串  
const userInfo = ref({})
const acceptStatus = ref()
const getContacts = async () => {
    try {

        const res = await get('/v1/api/contacts_url');

        acceptStatus.value = res.signing_status

    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const getUserInfo = async () => {
    try {

        const res = await get('/v1/api/user_info',);
        userInfo.value = res.user_info

        console.log(userInfo.value.card_no);
    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const afterRead1 = async (file) => {
  console.log(file);

  const options = {
    maxSizeMB: 0.3, // 最大文件大小（MB）
    maxWidthOrHeight: 800, // 最大宽度或高度
    useWebWorker: true, // 使用 Web Worker 加速压缩
  };

  try {
    const compressedFile = await imageCompression(file.file, options);
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64 = e.target.result;
      console.log(base64,22);
      imageSrc1.value = base64;
    };

    reader.readAsDataURL(compressedFile);
  } catch (error) {
    console.error('图片压缩失败:', error);
  }
};
const afterRead2 = async (file) => {
  console.log(file);

  const options = {
    maxSizeMB: 0.3, // 最大文件大小（MB）
    maxWidthOrHeight: 800, // 最大宽度或高度
    useWebWorker: true, // 使用 Web Worker 加速压缩
  };

  try {
    const compressedFile = await imageCompression(file.file, options);
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64 = e.target.result;
      console.log(base64);
      imageSrc2.value = base64;
    };

    reader.readAsDataURL(compressedFile);
  } catch (error) {
    console.error('图片压缩失败:', error);
  }
};

const save = async () => {
    console.log(imageSrc1.value);
    console.log(imageSrc2.value);
    try {
        const obj = {
            social_face: imageSrc1.value.split(',')[1],
            social_back: imageSrc2.value.split(',')[1],
            card_no: bankNumber.value,
            personal_social_no: userInfo.value.personal_social_no,
            name: userInfo.value.name
        }

        const res = await post('/v1/api/infomation', obj);

        showToast(res.message)

        if (res.code === 0) {
            if (acceptStatus.value === 0) {
                router.push('/contract')
            } else {
                router.push('/')
            }
        }
    } catch (error) {
        console.error(error); // 处理错误  
    }
}
onMounted(() => {
    getContacts()
    getUserInfo()
})
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 100vh;
    background-color: rgba(245, 247, 250, 1);

    .identity_card {
        padding: 21px 21px 30px 21px;
        background-color: rgba(255, 255, 255, 1);

        .uploadBox {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            background-color: #fff;

            .van-uploader {
                width: 44%;
                height: 102px;

                .uploadCard {
                    width: 135px;
                    height: 102px;
                    line-height: 102px;
                    text-align: center;

                    img {
                        width: 103%;
                        max-width: 100%;
                        /* 宽度最大为父元素的100% */
                        height: 102px;
                        object-fit: contain;
                        /* 保持宽高比，图片将被缩放以适应容器 */
                    }


                }
            }


        }
    }

    .personalInfo {

        padding: 0 15px;
        background-color: rgba(255, 255, 255, 1);

        .infoCell {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            height: 45px;
            padding: 18px 4px 0 4px;
            border-bottom: 1px solid #ECECEC;
            margin-bottom: 5px;
            .value{
                font-size: 16px;
            }

        }

        .infoBankCell {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            height: 70px;
            padding: 0 4px 0 4px;
            line-height: 70px;
            border-bottom: 1px solid #ECECEC;
            margin-bottom: 5px;

            .bankInput {
                width: 60%;
                margin: auto 0;
            }
        }


    }

    .save {
        margin: 25px auto;
        width: 320px;
        height: 50px;
        line-height: 50px;
        border-radius: 24px;
        background-color: rgba(38, 110, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        text-align: center;
        font-weight: 600;
    }
}

.title {
    display: flex;
    font-size: 16px;
    font-family: PingFangSC-regular;

    .symbol {
        color: red;
        margin-right: 2px;
    }
}

::v-deep .van-cell {
    padding: 0;
    height: 30px;

}

::v-deep .van-field__control {
    text-align: right;
    font-size: 16px
}
</style>