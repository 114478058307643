<template>
    <div class="container">

        <!-- 上传个人信息 -->
        <div class="personalInfo">
            <div class="infoCell">
                <div class="title">

                    <div class="text">姓名</div>
                </div>
                <div class="value">{{ userInfo.name }}</div>
            </div>
            <div class="infoCell">
                <div class="title">

                    <div class="text">手机号</div>
                </div>
                <div class="value">{{ userInfo.mobile }}</div>
            </div>
            <div class="infoCell">
                <div class="title">

                    <div class="text">身份证号</div>
                </div>
                <div class="value">{{ maskIdCard(userInfo.personal_social_no) }}</div>
            </div>
            <div class="infoBankCell">
                <div class="title">
                    <div class="symbol"></div>
                    <div class="text">银行卡号</div>
                </div>
                <div @click="goCardmodifi" class="value">{{ userInfo.card_no === '' ? '无银行卡信息' : bankIdCard(userInfo.card_no)
                    }}<van-icon name="arrow" /></div>
            </div>
            <div class="infoBankCell">
                <div class="title">
                    <div class="symbol"></div>
                    <div class="text">合同签署</div>
                </div>
                <div class="value" @click="goContract" v-if="acceptStatus === 0">待签署<van-icon name="arrow" /></div>
                <div class="value" @click="goContract" v-else>已签署<van-icon name="arrow" /></div>
            </div>
        </div>

    </div>
</template>
<script setup>

import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue';
import { get, post } from '@/utils/http';
const acceptStatus = ref()
const router = useRouter()
const userInfo = ref({})
// 个人信息

const getUserInfo = async () => {
    try {

        const res = await get('/v1/api/user_info',);
        userInfo.value = res.user_info

        console.log(userInfo.value, 333);
    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const maskIdCard = (idCard) => {
    console.log(idCard, 77777777);
    if (idCard === undefined) {
        return
    }
    const idCardSte = String(idCard)
    const masked = idCardSte.slice(0, 4) + '********' + idCard.slice(-4);
    return masked;
}
const bankIdCard = (idCard) => {
    if (idCard === undefined) {
        return
    }
    const idCardSte = String(idCard)
    let masked = idCardSte.slice(0, 4);
    let rest = idCardSte.slice(4, -4);
    for (let i = 0; i < rest.length; i += 4) {
        if (i > 0) {
            masked += ' ';
        }
        masked += ' **** ';
    }

    masked += idCardSte.slice(-4);

    return masked;
}
const goCardmodifi = () => {

    router.push('/individual/cardmodifi')
}
const goContract = () => {
    router.push('/contract')

}
const getContacts = async () => {
    try {

        const res = await get('/v1/api/contacts_url');
     
        acceptStatus.value = res.signing_status

    } catch (error) {
        console.error(error); // 处理错误  
    }
}
onMounted(() => {
    getContacts()
    getUserInfo()
})
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 100vh;
    background-color: rgba(245, 247, 250, 1);


    .personalInfo {

        padding: 0 15px;
        background-color: rgba(255, 255, 255, 1);

        .infoCell {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            height: 45px;
            padding: 18px 4px 0 4px;
            border-bottom: 1px solid #ECECEC;
            margin-bottom: 5px;
            .title{

            }
            .value{
                font-size: 16px;
            }
        }

        .infoBankCell {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            height: 60px;
            padding: 0 4px 0 4px;
            line-height: 70px;
            border-bottom: 1px solid #ECECEC;
            margin-bottom: 5px;
            .title{

            }
            .value{
                font-size: 16px;
            }
        }
    }
}

.title {
    display: flex;
    font-size: 16px;
    font-family: PingFangSC-regular;
}
</style>