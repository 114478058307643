<template>
    <div class="container">
        <div class="head">
            <div class="arrow" @click="back"><van-icon size="24" name="arrow-left" /></div>修改银行卡
        </div>
        <div class="infoBankCell">
            <div class="title">

                <div class="text">卡号</div>
            </div>
            <div class="bankInput"><van-field maxlength="19" type="number" v-model="bankNumber" placeholder="请输入银行卡号" />
            </div>
        </div>
        <div @click="confirm" class="save">确认修改</div>
    </div>
</template>
<script setup>
import { get, post } from '@/utils/http';
import { useRouter } from 'vue-router'
import { showSuccessToast, showFailToast } from 'vant';
const router = useRouter()
import { ref, onMounted } from 'vue'
const userInfo = ref({})
const bankNumber = ref()

const back = () => {
    router.back()
}
// 个人信息
const getUserInfo = async () => {
    try {

        const res = await get('/v1/api/user_info',);
        userInfo.value = res.user_info

        console.log(userInfo, '个人信息');
    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const postInfomation = async () => {
    // if (bankNumber.value.length !== 16) {
    //     showFailToast('卡号不完整!');
    //     return
    // }
    try {
        console.log(userInfo.personal_social_no);
        const res = await post('/v1/api/infomation', {
            card_no: bankNumber.value,
            personal_social_no: userInfo.value.personal_social_no,
            name: userInfo.value.name
        });
        console.log(res);
        if (res.code === 0) {
            showSuccessToast('修改成功!')
            setTimeout(() => {
                router.push('/individual')
            }, 2000);
        } else {
            showFailToast(res.message);

        }

    } catch (error) {
        console.error(error); // 处理错误  
        showFailToast('服务器错误!');
    }
}
const confirm = () => {
    console.log('确认修改');
    postInfomation()

}
onMounted(() => {
    getUserInfo()
})
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 100vh;
    background-color: rgba(245, 247, 250, 1);

    .head {
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: relative;

        .arrow {
            position: absolute;
            width: 10%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }
    }

    .infoBankCell {
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        height: 70px;
        padding: 0 4px 0 4px;
        line-height: 70px;
        border-bottom: 1px solid #ECECEC;
        margin-bottom: 5px;

        .bankInput {
            width: 60%;
            margin: auto 0;
        }
    }

    .title {
        padding-left: 10px;
        display: flex;
        font-size: 16px;
        font-family: PingFangSC-regular;

        .symbol {
            color: red;
            margin-right: 2px;
        }
    }

    .save {
        margin: 400px auto;
        width: 320px;
        height: 50px;
        line-height: 50px;
        border-radius: 24px;
        background-color: rgba(38, 110, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        text-align: center;
        font-weight: 600;
    }

    ::v-deep .van-cell {
        padding: 0;
        height: 30px;

    }

    ::v-deep .van-field__control {
        padding-right: 20px;
        text-align: right;
        font-size: 16px
    }
}
</style>