<template>
    <div class="container">
        <div class="head">
            <div class="arrow" @click="back"><van-icon size="24" name="arrow-left" /></div>账单明细
        </div>
        <div class="content">
            <div class="name">{{ list.name }}</div>
            <div class="name" v-if="list.bank">银行账户{{ `(${list?.bank?.slice(-4)})`}}</div>
            <div class="amount" v-if="list.name">+{{ list.amount }}</div>
            <div class="amount" v-else>-{{ list.amount }}</div>
            <div v-if="list.status === '0' && list.task" class="status" style="color: red;">未提现</div>
            <div v-else-if="list.status === '1' && list.task" class="status" style="color: red;">提现中</div>
            <div v-else-if="list.status === '2' && list.task" class="status" style="color: blue;">已提现</div>
            <div v-else-if="list.status === '50' && list.bank" class="status" style="color: blue;">下发成功</div>
            <div v-else-if="(list.status === '-10' || list.status === '-15') && list.bank" class="status" style="color: red;">下发失败</div>
            <div v-else-if="list.bank" class="status" style="color: red;">提现中</div> 
            <div class="time">
                <div class="left">创建时间</div>
                <div class="right">{{ list.time }}</div>
            </div>
            <div class="number">
                <div class="left">账单编号</div>
                <div class="right">{{ list.number }}</div>
            </div>
            <div class="task" v-if="list.task">
                <div class="left">客户名称</div>
                <div class="right">{{ list.task }}</div>
            </div>
            <div class="task" v-if="list.task">
                <div class="left">客户编码</div>
                <div class="right">{{ list.khbm }}</div>
            </div>
            <div  class="task" v-if="list.task">
                <div class="left">任务内容</div>
                <div class="right" style="color: gray;">{{ list.rwnr }}</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
import { ref, onMounted } from 'vue';
const list = ref({})
onMounted(() => {
    console.log(route.query);
    list.value = route.query
    console.log(list.value);
})
const back = () => {
    router.back()
}  
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 100vh;
    background-color: rgba(245, 247, 250, 1);

    .head {
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        font-size: 24px;
        .arrow {
            position: absolute;
            width: 10%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
          
        }
    }

    .content {
        background-color: rgba(255, 255, 255, 1);
        min-height: 240px;
        padding-bottom: 30px;
        font-family: PingFangSC-regular;
        padding-top: 36px;

        .name {
            font-size: 18px;
            text-align: center;
           
        }

        .amount {
            font-size: 36px;
            margin-top: 5px;
            text-align: center;
        }
        .status{
            margin-top: 10px;
            text-align: center;
            font-size: 20px;
        }

        .time {
            display: flex;
            font-size: 16px;
            margin-top: 18px;
            padding: 0 16%;
      
            .left{
                width: 80px;
                
            }
            .right{
                width: 200px;
                margin-left: 20px;
            
            }
        }

        .task {
            display: flex;
            font-size: 16px;
            margin-top: 8px;
            padding: 0 16%;
            .left{
                width: 80px;
                
            }
            .right{
                width: 200px;
                margin-left: 20px;
            
            }
        }

        .number {
            display: flex;
         
            font-size: 16px;
            margin-top: 8px;
            padding: 0 16%;
            
           
            .left{
                width: 80px;
                
            }
            .right{
                width: 200px;
                margin-left: 20px;
            
            }
        }
    }


}
</style>