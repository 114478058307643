import axios from 'axios';
const baseUrl = 'http://jiugui.guduokeji.com'
// 创建一个axios实例  
const service = axios.create({
    // baseURL: baseUrl,
    timeout: 20000, // 请求超时时间  ,
    withCredentials: true
});

// 请求拦截器  
service.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么，比如添加请求头  
        if (localStorage.getItem('token')) {
            // 假设token是存储在localStorage中的  
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        }
        config.headers['Content-Type'] = 'text/plain';
        // config.headers['Cookie'] = 'sessionid=o6kwlpyl2lbws3q8uz2mb9mti4ni2n3w';
        return config;
    },
    error => {
        // 对请求错误做些什么  
        console.log(error); // for debug  
        return Promise.reject(error);
    }
);

// 响应拦截器  
service.interceptors.response.use(
    response => {
        const res = response.data;
        // 在这里可以对响应数据做些什么，比如统一处理错误信息等  
        // if (res.code !== 200) {
        //     // 如果后端返回的不是200，则认为是错误，并弹出提示信息  
        //     return Promise.reject(new Error(res.message || 'Error'));
        // }
        return res;
    },
    error => {
        console.log('err' + error); // for debug  
        return Promise.reject(error);
    }
);
// 封装 get 请求  
export function get(url, params = {}) {
    return service({
        url: url,
        method: 'get',
        params
    });
}

// 封装 post 请求  
export function post(url, data = {}, config = {}) {
    return service({
        url: url,
        method: 'post',
        data,
        ...config // 允许传入额外的配置  
    });
}

export default service;