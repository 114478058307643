import { createRouter, createWebHistory } from "vue-router";
import RefineView from '../views/Refine/index.vue'
import ContractView from '../views/Contract/index.vue'
import ManagementView from '../views/Management/index.vue'
import IndividualView from '../views/Individual/index.vue'
import CardmodifiView from '../views/Individual/components/Cardmodifi/index.vue'
import AccountdetailView from '../views/Management/components/accountDetail/index.vue'
const routes = [
  {
    path: "/",
    name: "management",
    component: ManagementView,
  },
  {
    path: "/management/accountdetail",
    name: "accountdetail",
    component: AccountdetailView,
  },
  {
    path: "/individual",
    name: "individual",
    component: IndividualView,
  },
  {
    path: "/individual/cardmodifi",
    name: "cardmodifi",
    component: CardmodifiView,
  },
  {
    path: "/contract",
    name: "contract",
    component: ContractView,
    meta: { hideNav: true },
  },
  {
    path: "/refine",
    name: "refine",
    component: RefineView,
    meta: { hideNav: true },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
